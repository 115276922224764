<template>
    <section class="validation">
        <div>
            <div class="page-header">
                <h3 class="page-title">
                    Edit Profile
                </h3>
                <!-- <div class="button-group">
                    <button type="button" class="btn btn-info" @click="goBack()">
                        <i class="mdi mdi-keyboard-backspace"></i> Back</button>
                </div> -->
            </div>
            <div class="row">
                <div class="col-lg-7 m-auto">
                    <div class="card">
                        <div class="card-body">


                            <div class="col-md-12 mb-4">
                                            <button class="btn btn-outline-success"
                                            @click="sendResetLinkToUser(form.email)">Send Password Reset Link
                                            <i class="mdi mdi-send" style="font-size:12px"></i></button>
                                        </div>

                            <b-form @submit.stop.prevent="onSubmit">
                                <div class="container-fluid my-4">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <!-- <div class="file-box" style="padding:10px 20px; cursor: default;"> -->
                                            <b-form-group id="example-input-group-1" label="Name"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" placeholder="Enter Username"
                                                    name="example-input-1" v-model="$v.form.name.$model"
                                                    :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>
                                            </b-form-group>
                                            <!-- </div> -->
                                        </div>
                                        <div class="col-md-12">
                                            <!-- <div class="file-box" style="padding:10px 20px; cursor: default;"> -->
                                            <b-form-group id="example-input-group-1" label="Email"
                                                label-for="example-input-1">
                                                <b-form-input type="email" readonly="true" id="example-input-1" placeholder="Enter Email"
                                                    name="example-input-1" v-model="$v.form.email.$model"
                                                    :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>
                                            </b-form-group>
                                            <!-- </div> -->
                                        </div>

                                        <!-- <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInput">Old Password</label>
                                                <div class="input-group">
                                                    <input  :type="passwordFieldType_old" class="form-control"
                                                        v-model="form.password_old" id="inlineFormInputGroup"
                                                        placeholder="Enter Old Password">
                                                    <div class="input-group-prepend" style="cursor:pointer"
                                                        @click="showOldPassword()">
                                                        <div class="input-group-text"><i :class="eyeIcon_old"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="err" v-if="pass_old_err != ''">{{ pass_old_err }}</p>
                                        </div>
                                      


                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInput">Password</label>
                                                <div class="input-group">
                                                    <input :type="passwordFieldType" class="form-control"
                                                        v-model="form.password"
                                                        id="inlineFormInputGroup"
                                                        placeholder="Password">
                                                    <div class="input-group-prepend" style="cursor:pointer"
                                                        @click="showPassword()">
                                                        <div class="input-group-text"><i :class="eyeIcon"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="err" v-if="pass_err != ''">{{ pass_err }}</p>
                                        </div>


                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Confirm Password</label>
                                                <div class="input-group">
                                                    <input :type="passwordFieldType_confirm" class="form-control"
                                                        v-model="form.password_confirm" id="inlineFormInputGroup"
                                                        placeholder="Enter Password for confirmation">
                                                    <div class="input-group-prepend" style="cursor:pointer"
                                                        @click="showConfirmPassword()">
                                                        <div class="input-group-text"><i :class="eyeIcon_confirm"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="err" v-if="pass_confirm_err != ''">{{ pass_confirm_err }}</p>
                                        </div> -->


                                        <div class="col-md-12">

                                            <div class="file-box d-flex" @click="$refs.image.click()">
                                                <div class="col-md-1">
                                                    <i class="mdi mdi-cloud-upload" style="font-size:50px"></i>
                                                    <input class="ml" type="file" 
                                                        @change="onAudioImageChange" ref="image"
                                                        style="display: none" />
                                                </div>
                                                <div class="col-md-8">
                                                    <div class="file-text-box">
                                                        <span>Upload</span><br />
                                                        <small>Upload category image here</small>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <img v-if="image_url !== ''" :src="image_url"
                                                        class="img-thumbnail rounded-circle" alt="Image"
                                                        style="height: 65px; width: 65px; margin-top:8px" />
                                                </div>
                                            </div>
                                            <p class="err" v-if="image_error != ''">{{ image_error }}</p>
                                        </div>
                                    </div>

                                    <div class="text-right mt-3">
                                        <b-button :disabled='is_loading' type="submit" variant="success"
                                            class="btn believe-btn btn-block">
                                            <i
                                                :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                            Submit
                                        </b-button>
                                    </div>
                                </div>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vue-snotify></vue-snotify>
    </section>

</template>
<script>
import { validationMixin } from 'vuelidate'
import { required,email,minLength } from 'vuelidate/lib/validators'
import api from "../../config/api.js";
// import { getAuth, sendPasswordResetEmail } from "firebase/auth";
export default {
    name: 'AudioCategoryEditModal',
    mixins: [validationMixin],
    data() {
        return {
            msg: "",
            is_loading: false,
            image_error: "",
            image_url: "",
            passwordFieldType: 'password',
            passwordFieldType_confirm: 'password',
            passwordFieldType_old: 'password',
            eyeIcon: 'fa fa-eye',
            eyeIcon_confirm: 'fa fa-eye',
            eyeIcon_old: 'fa fa-eye',
            pass_confirm_err : null,
            pass_old_err : null,
            pass_err : null,
            form: {
                id: null,
                name: null,
                image: "",
                email: null,
                password: null,
                password_old: null,
                password_confirm: null,
            }
        }
    },
    validations: {
        form: {
            name: {
                required,
            },
            email: {
                email,
                required,
            },
            // password: {
            //     minLength
            // }
        }
    },

    mounted() {
        this.items()
    },
    methods: {

    // async sendResetLinkToUser(email) {
    //   const auth = getAuth();
    //   const firebase_response = await sendPasswordResetEmail(
    //     auth, email
    //   );
    // //   alert('link sent');
    //   this.$swal('Success!', 'Link send successfully, Kindly check your email.', 'success')
    // },

    async sendResetLinkToUser(email) {
      try {
        await api.post(`/admin/forgot-password`, {
          email: email,
        });
        // alert('link sent');
        this.$swal('Success!', 'Link send successfully, Kindly check your email.', 'success')
      } catch (error) {
        this.$swal('Error!', 'Something went wrong, please try again!', 'error')
        // alert('something went wrong, please try again!')
      }
    },

        showConfirmPassword() {
            if (this.passwordFieldType_confirm == "password") {
                this.passwordFieldType_confirm = "text"
                this.eyeIcon_confirm = "fa fa-eye-slash"
            } else {
                this.passwordFieldType_confirm = "password"
                this.eyeIcon_confirm = "fa fa-eye"
            }
        },

        showOldPassword() {
            if (this.passwordFieldType_old == "password") {
                this.passwordFieldType_old = "text"
                this.eyeIcon_old = "fa fa-eye-slash"
            } else {
                this.passwordFieldType_old = "password"
                this.eyeIcon_old = "fa fa-eye"
            }
        },
        
        showPassword() {
            if (this.passwordFieldType == "password") {
                this.passwordFieldType = "text"
                this.eyeIcon = "fa fa-eye-slash"
            } else {
                this.passwordFieldType = "password"
                this.eyeIcon = "fa fa-eye"
            }
        },
        goBack() {
            let prev_url = `/hypnosis-categories/list/`;
            this.$router.push({ path: prev_url });
        },
        async items() {
            // let data = await this.$store.getters['getEditFormData'];
            // let id = await this.$store.getters['getid'];
            let name = await this.$store.getters['getname'];
            let image = await this.$store.getters['getimage'];
            let email = await this.$store.getters['getemail'];

            // if (id == null || id == "") {
            //     id = localStorage.getItem('user_id');
            // }
            if (name == null || name == "") {
                name = localStorage.getItem('user_name');
            }
            if (email == null || email == "") {
                email = localStorage.getItem('user_email');
            }
            if (image == null || image == "") {
                image = localStorage.getItem('user_image');
            }

            // if (data.length == 0) {
            //     data = localStorage.getItem('data_for_edit');
            //     data = JSON.parse(data);
            // }
            this.form.name = name;
            this.form.email = email;
            this.image_url = image;
            this.form.id = id;

        },
        onAudioImageChange(e) {
            this.image = "";
            const file = e.target.files[0];
            var status = this.checkFileType(file, 'image1')
            if (status) {
                this.image_error = "";
                this.form.image = file;
                this.image_url = URL.createObjectURL(file)
            }
        },

        checkFileType(file, num) {
            var types = ['image/jpeg', 'image/png', 'image/jpg']
            var msg = "The image must be a file of type: jpeg, jpg, png";
            if (!types.includes(file.type)) {
                if (num == 'image1') {
                    this.image_error = msg
                    this.form.image = "";
                }
                return false;
            }
            return true;
        },

        async setStore() {
            try {
                let result = await api.get("admin/verify");
                this.user_name = result.data.name;
                this.user_email = result.data.email;
                this.user_image = result.data.profile_image;
                this.user_type = result.data.type;
                this.user_status = result.data.status;
                this.utoken = localStorage.getItem("loginkey");

                this.$store.commit("getname", this.user_name);
                // console.log('user name from set store in profile edit modal : ' , this.user_name);
                localStorage.setItem('user_name', this.user_name);

                this.$store.commit("getkey", this.utoken);
                this.$store.commit("getimage", this.user_image);

                localStorage.setItem('user_image', this.user_image);
                this.$store.commit("gettype", this.user_type);
                this.$store.commit("getemail", this.user_email);
                localStorage.setItem('user_email', this.user_email);
                this.$store.commit("getstatus", this.user_status);
            } catch (error) {
                console.log(error);
            }
        },

        async onSubmit() {
            this.pass_confirm_err = null;
            this.pass_old_err = null;
            this.pass_err = null;
            this.$v.form.$touch()
            if (this.$v.form.$anyError || this.image_error != "") {
                return false;
            }

            if(this.form.password != null){
                if(this.form.password.length < 6){
                    this.pass_err = "Password should have 6 characters minimum"
                    return false;
                }

                if(this.form.password_confirm == null || this.form.password_confirm == ""){
                    this.pass_confirm_err = "Please re enter password for confirmation"
                    return false;
                }else if(this.form.password_confirm != this.form.password){
                    this.pass_confirm_err = "Password not match"
                    return false;
                }
                if(this.form.password_old == null || this.form.password_old == ""){
                    // console.log('after');
                    this.pass_old_err = "Please enter old password"
                    return false;
                }
            }

            try {
                this.is_loading = true;
                // const obj = this.$store.getters['getEditFormData'];
                let endpoint = "/admin/update-profile";
                const formData = new FormData();
                // formData.append("id", this.form?.id);
                formData.append("name", this.form?.name);
                formData.append("email", this.form?.email);
                if(this.form.password != null && this.form.password != ""
                && this.form.password_confirm != null && this.form.password_confirm != ""
                && this.form.password_old != null && this.form.password_old != ""){
                    formData.append("password", this.form?.password);
                    formData.append("confirm_password", this.form?.password_confirm);
                    formData.append("old_password", this.form?.password_old);
                }
                if (this.form?.image) {
                    formData.append("profile_image", this.form?.image);
                }
                let result = await api.post(endpoint, formData);
                if (result.status == 200) {
                    const msg = await result.data.message;
                    // this.$store.dispatch('setMessage', { msg });
                    this.$swal('Success!', msg, 'success')
                    // await this.$store.dispatch(`${type[1]}/all`);
                    this.setStore();
                    this.is_loading = false;
                }
            } catch (error) {
                // this.progress = 0;
                // let err = error.response.data.errors.confirm_password;

                this.$swal('Something went wrong!', error?.response?.data?.message , 'error')
                this.is_loading = false;
                // this.$store.dispatch('setMessage', { err });
                // this.$root.$refs.Category.showSnotifyError()
                // this.$toast.error(this.error);
            }
        },
    },


}
</script>